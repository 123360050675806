import request from '@/directives/request'
import config from '@/config'

//车场列表
export function parkingList(data) {
    return request({
        url: `${config.bpi}/carOwner/user/parkingList`,
        method: 'get',
        params: data,
    })
}

//绑定车辆
export function bindingCar(data) {
    return request({
        url: `${config.bpi}/carOwner/user/bindingCar`,
        method: 'put',
        data: data,
        header: {
            'Content-Type': 'application/json',
        }
    })
}

//我的长租车
export function longCarList(params) {
    return request({
        url: `${config.bpi}/carOwner/user/longCarList`,
        method: 'get',
        params
    })
}

//获取中央支付订单
export function centerParkingList(data) {
    return request({
        url: `${config.bpi}/carOwner/user/tempPay/center`,
        method: 'get',
        params: data,
    })
}

//获取临停支付订单信息
export function licensePlate(roadId) {
    return request({
        url: `${config.bpi}/carOwner/user/tempPay/licensePlate?roadId=` + roadId,
        method: 'get',
    })
}

//添加无牌车
export function addUnlicensedCar(roadId) {
    return request({
        url: `${config.bpi}/carOwner/user/addUnlicensedCar`,
        method: 'post',
        params: {roadId: roadId}
    })
}

//我的优惠券列表
export function couponList(params) {
    return request({
        url: `${config.bpi}/carOwner/user/couponList`,
        method: 'get',
        params
    })
}

//领券
export function collectCoupons(params) {
    return request({
        url: `${config.bpi}/carOwner/user/collectCoupons`,
        method: 'get',
        params
    })
}

//优惠券信息
export function couponInfo(id) {
    return request({
        url: `${config.bpi}/carOwner/user/coupon`,
        method: 'get',
        params: {id: id}
    })
}

// 计算优惠券价格
export function userCoupon(params) {
    return request({
        url: `${config.bpi}/carOwner/user/userCoupon`,
        method: 'get',
        params
    })
}

//临停支付
export function tempPay(data) {
    return request({
        url: `${config.bpi}/carOwner/user/tempPay/create`,
        method: 'put',
        data: data,
        header: {
            'Content-Type': 'application/json',
        }
    })
}

//获取无牌车订单
export function unlicensedOrder(roadId) {
    return request({
        url: `${config.bpi}/carOwner/user/unlicensedOrder?roadId=` + roadId,
        method: 'get',
    })
}

//长租车解绑
export function longCar(id, licensePlate, parkingId) {
    return request({
        url: `${config.bpi}/carOwner/user/longCar`,
        method: 'delete',
        params: {chargeId: id, licensePlate: licensePlate, parkingId: parkingId}
    })
}

//长租车缴费记录
export function longPayRecord(data) {
    return request({
        url: `${config.bpi}/carOwner/user/longPayRecord`,
        method: 'get',
        params: data
    })
}

//长租订单信息
export function longOrderInfo(data) {
    return request({
        url: `${config.bpi}/carOwner/user/longOrderInfo`,
        method: 'get',
        params: data
    })
}

//长租车续费
export function longCarRenew(data) {
    return request({
        url: `${config.bpi}/carOwner/user/longCarRenew`,
        method: 'put',
        params: data
    })
}

//增加无人值守人员
export function unattended(parkingId) {
    return request({
        url: `${config.bpi}/carOwner/user/unattended?parkingId=` + parkingId,
        method: 'post',
    })
}

//获取车场信息
export function getParking(parkingId) {
    return request({
        url: `${config.bpi}/carOwner/user/parking`,
        method: 'get',
        params: {parkingId: parkingId}
    })
}

//获取微信配置
export function getInfo(url) {
    return request({
        url: `${config.api}/endUser/wxConfig`,
        method: 'get',
        params: {url: url}
    })
}

//无人值守通知
export function getNotice(roadId) {
    return request({
        url: `${config.bpi}/unattended/notice?roadId=` + roadId,
        method: 'put',
    })
}

//获取appI和secret
export function getIsSubscribe(openId) {
    return request({
        url: `${config.api}/endUser/isSubscribe`,
        method: 'get',
        params: {openId: openId}
    })
}


// 文件上传
export function uploadFile(data) {
    return request({
        url: `${config.api}/common/uploadFile`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

//项目列表
export function getProjectSearch(data) {
    return request({
        url: `${config.api}/project/search`,
        method: 'get',
        params: data
    })
}

// 访客列表
export function getVisitorList(data) {
    return request({
        url: `${config.api}/visitor/list`,
        method: 'get',
        params: data,
    })
}

// 新增访客
export function addAppointment(data) {
    return request({
        url: `${config.api}/visitor/appointment`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//广告
export function getAdvertisement(data) {
    return request({
        url: `${config.api}/ad/service/location`,
        method: 'get',
        params: data,
    })
}

//获取订单信息
export function getOrderInfo(data) {
    return request({
        url: `${config.api}/carOwner/user/order`,
        method: 'get',
        params: data,
    })
}

//车牌/微信/支付宝是否已绑定
export function carBinded(data) {
    return request({
        url: `${config.bpi}/carOwner/user/car/binded`,
        method: 'get',
        params: data,
    })
}

//车牌/微信/支付宝绑定
export function carBind(data) {
    return request({
        url: `${config.bpi}/carOwner/user/car/bind`,
        method: 'post',
        params: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//临停支付完成页
export function payTempFinish(data) {
    return request({
        url: `${config.bpi}/pay/temp/finish`,
        method: 'get',
        params: data,
    })
}

//长租完成页
export function payLongFinish(data) {
    return request({
        url: `${config.bpi}/pay/long/finish`,
        method: 'get',
        params: data,
    })
}

//开通ETC缴费
export function etcOpen(data) {
    return request({
        url: `${config.bpi}/carOwner/user/charge/etc/open`,
        method: 'post',
        params: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//开通发票
export function invoiceOpen(data) {
    return request({
        url: `${config.api}/invoice`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//无人值守道路
export function uattendedInfo(data) {
    return request({
        url: `${config.bpi}/unattended/road?roadId=` + data,
        method: 'get',
    })
}

//获取生成的userSig
export function signUser(data) {
    return request({
        url: `${config.bpi}/unattended/sign/user?userName=` + data,
        method: 'get',
    })
}

//无人值守通知
export function uattendedNotice(roadId, userId) {
    return request({
        url: `${config.bpi}/unattended/notice?roadId=` + roadId + `&userId=` + userId,
        method: 'put'
    })
}

//挂断
export function handUpNotice(roadId, userId) {
    return request({
        url: `${config.bpi}/unattended/handUpNotice?roadId=` + roadId + `&userId=` + userId,
        method: 'put'
    })
}

//提交申请
export function userRequest(data) {
    return request({
        url: `${config.cpi}/user/request`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//人员申请列表
export function userRequestlist(data) {
    return request({
        url: `${config.cpi}/user/request/list`,
        method: 'get',
        params: data
    })
}

//查询人员信息
export function queryUserList(data) {
    return request({
        url: `${config.cpi}/user/search`,
        method: 'get',
        params: data,
    })
}
// 区域树列表
export function getTreeData(data) {
    return request({
        url: `${config.cpi}/org/treeData`,
        method: 'get',
        params: data,
    })
}
