<template>
    <div>
        <div class="bgColor">
            <div class="content">
                <div class="text1">开票金额</div>
                <div class="price"><span>￥</span>{{amount}}</div>
                <van-divider/>
                <van-cell title="车场名称" :value="parkingName"/>
                <van-form ref="form">
                    <van-field name="radio" label="发票类型" required>
                        <template #input>
                            <van-radio-group v-model="invoiceType" direction="horizontal">
                                <van-radio name="1" checked-color="#EC7955">企业开票</van-radio>
                                <van-radio name="2" checked-color="#EC7955">个人开票</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <van-field v-if="columns.length > 0"
                               v-model="taxName"
                               is-link
                               readonly
                               required
                               name="picker"
                               label="抬头"
                               @click="handleShow"
                               :rules="[{ required: true, message: '请选择抬头'}]"
                    />
                    <van-field v-else
                               v-model="taxName"
                               required
                               name="抬头"
                               label="抬头"
                               :rules="[{ required: true, message: '请填写抬头' }]"
                    />
                    <van-popup v-model="showPicker" position="bottom">
                        <van-picker
                                :columns="columns"
                                value-key="id"
                                show-toolbar
                                @confirm="onConfirm"
                                @cancel="showPicker = false">
                            <template #option="option">
                                <div style="display: flex; flex-direction: column; align-items: center;">
                                    <div>{{ option.taxName }}</div>
                                </div>
                            </template>
                        </van-picker>
                    </van-popup>
                    <van-field
                            v-model="taxNo"
                            name="税号"
                            label="税号"
                    />
                    <van-field required
                               v-model="email"
                               name="接收邮箱"
                               label="接收邮箱"
                               :rules="[{ required: true, message: '请填写接收邮箱' }]"
                    />
                    <van-field
                            v-model="mobileNum"
                            name="接收手机"
                            label="接收手机"
                            type="tel"
                    />
                    <div style="margin: 16px;">
                        <van-button class="cx" block type="primary" @click="onSubmit">
                            开发票
                        </van-button>
                    </div>
                </van-form>
            </div>
        </div>
    </div>
</template>

<script>
    import {Divider, RadioGroup, Radio, Button, Form, Field, Toast, Cell, Dialog, Popup, Picker} from 'vant';
    import {getParking, invoiceOpen} from "../api/interface";

    export default {
        name: "invoice",
        components: {
            [Divider.name]: Divider,
            [RadioGroup.name]: RadioGroup,
            [Radio.name]: Radio,
            [Button.name]: Button,
            [Form.name]: Form,
            [Field.name]: Field,
            [Cell.name]: Cell,
            [Dialog.name]: Dialog,
            [Popup.name]: Popup,
            [Picker.name]: Picker,
        },
        data() {
            return {
                voiceChannel: "",
                amount: "",
                invoiceType: "1",
                taxName: "",
                taxNo: "",
                email: "",
                mobileNum: "",
                orderNo: "",
                parkingName: "",
                showPicker: false,
                columns: [],
            }
        },
        created() {
            this.amount = this.$route.query.amount;
            this.parkingName = this.$route.query.parkingName;
            this.columns = JSON.parse(localStorage.getItem("columns")) || [];
            getParking(this.$route.query.parkingId).then((res) => {
                sessionStorage.setItem('projectId', res.data.data.projectId);

            })
        },
        methods: {
            onSubmit() {
                this.$refs.form.validate().then(() => {
                    var projectId = sessionStorage.getItem("projectId");
                    let param = {
                        invoiceChannel: "INVOICE_PP",
                        userId: sessionStorage.getItem("userId"),
                        orderType: this.$route.query.orderType,
                        amount: this.$route.query.amount * 100,
                        invoiceType: this.invoiceType == 1 ? "COMPANY" : "PERSONAL",
                        taxName: this.taxName,
                        taxNo: this.taxNo,
                        email: this.email,
                        mobileNum: this.mobileNum,
                        orderNo: this.$route.query.order_no,
                        projId: parseInt(projectId)
                    };
                    invoiceOpen(param).then(res => {
                        if (res.data.code == 'SUCCESS') {
                            Dialog.alert({
                                title: "温馨提示",
                                message: "您本次的开票金额为：" + this.amount + "元，开票申请已提交，成功后发票会发送至您的邮箱，同时请关注您的手机短信。",
                                confirmButtonColor: '#FE4D13'
                            }).then(() => {
                                this.weixinClosePage();
                            });
                        }
                    });
                    var list = [param];
                    var arr = this.columns.concat(list);
                    this.columns = this.unique(arr);
                    localStorage.setItem("columns", JSON.stringify(this.columns));
                }).catch(() => {
                    Toast({message: '必填项不能为空，提交失败！', duration: 2000})
                })
            },
            unique(arr) {
                const res = new Map();
                return arr.filter((arr) => !res.has(arr.taxName) && res.set(arr.taxName, 1))
            },
            handleShow() {
                this.showPicker = true;
            },
            onConfirm(value) {
                this.showPicker = false;
                this.taxName = value.taxName;
                this.taxNo = value.taxNo;
                this.email = value.email;
                this.mobileNum = value.mobileNum;
                // this.voiceChannel = value.voiceChannel;
                // this.amount = value.amount;
                // this.invoiceType = value.invoiceType;
                // this.orderNo = value.orderNo;
                // this.userId = value.userId;
                // this.orderType = value.orderType;
            },
            weixinClosePage() {
                if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                        document.addEventListener('WeixinJSBridgeReady', this.weixin_ClosePage, false);
                    } else if (document.attachEvent) {
                        document.attachEvent('WeixinJSBridgeReady', this.weixin_ClosePage);
                        document.attachEvent('onWeixinJSBridgeReady', this.weixin_ClosePage);
                    }
                } else {
                    this.weixin_ClosePage();
                }
            },
            weixin_ClosePage() {
                WeixinJSBridge.call('closeWindow');
            }
        },
        beforeRouteEnter(to, from, next) {
            // 添加背景色
            document.querySelector('body').setAttribute('style', 'background-color:#F9F9F9')
            next()
        },
        beforeRouteLeave(to, from, next) {
            // 去除背景色
            document.querySelector('body').setAttribute('style', '')
            next()
        }
    }
</script>


<style scoped>
    .bgColor {
        background-color: white;
        padding: 10px;
        margin-bottom: 20px;
    }

    .content {
        display: flex;
        flex-direction: column;
        font-size: 16px;
    }

    .text1 {
        display: flex;
        justify-content: start;
        margin-left: 15px;
        color: #8a8a8a;
        font-weight: 200;
    }

    .price {
        color: #FD4A1B;
        font-size: 30px;
        font-weight: bold;
    }

    .price span {
        font-size: 20px;
    }

    .itemContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        line-height: 40px;
        font-weight: 400;
    }

    .text2 {
        color: #8a8a8a;
        font-weight: 200;
    }

    /deep/ .van-divider {
        margin: 10px 0;
    }

    .cx {
        color: #ffffff;
        font-size: 18px;
        width: 302px;
        background: linear-gradient(-30deg, #DC5040, #EC7955);
        border-radius: 5px;
        border: none;
        font-weight: 500;
        margin: 20px 0;
    }

    .van-cell__title {
        text-align: start;
    }
</style>
